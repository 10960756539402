.culture {
	.tabs {
		margin: 0;
		ul {
			border: none;
		margin: 0;
		justify-content: center;
        }
        li a {
			border-bottom-color: transparent;
            opacity: 0.5;
            transition: all 0.2s ease;
		}
        li a:hover,
        li.is-active a {
            opacity: 1;
		}
	}
	.tab-container {
		margin: $gap * .5 0 $gap 0;
		padding: $gap / 2;
		box-shadow: 0 0 32px rgba($black, 0.1);
		@media (min-width: $desktop) {
			padding: $gap;
		}
		p {
			&:first-of-type {
				margin-top: $gap / 3;
				font-size: 1.5em;
			}
		}
		.tab-wide-images {
			margin-top: $gap;
		}
	}
}