.hero.home {
	background-color: $brand-heading;
	.hero-body {
		padding: 0;
		// height: 36rem;
		height: calc(75vh - 50px);
		#video {
			z-index: 1;
			overflow: hidden;
			height: 100%;
			position: relative;
			.container {
				padding-top: $gap * 2;
				z-index: 5;
				display: flex;
				justify-content: center;
				flex-direction: column;
				height: 100%;
				h1 {
					// font-size: 5rem;
					line-height: 1;
					margin-bottom: 1px;
					position: relative;
					z-index: 5;
					&::after {
						content: "";
						width: 140px;
						height: 140px;
						position: absolute;
						top: 10px;
						left: -70px;
						background-color: rgba($primary, 0.25);
						border-radius: 50%;
						background-blend-mode: multiply;
						z-index: -1; 
					}
				}
				p {
					font-size: 18px;
					z-index: 5;
				}
			}
		}
		
	}
}
@media screen and (min-width: $tablet) {
	.hero.home {
		.hero-body {
			height: 75vh;
			min-height: 600px;
			#video {
				.container {
					h1 {
						font-size: 5rem;
						&::after {
							width: 210px;
							height: 210px;
							left: -105px;
						}
					}
					p {
						font-size: 24px;
						max-width: 860px;
					}
				}
			}
			
		}
	}
}

// .background-video {
// 	background-position: top center;
// 	background-repeat: no-repeat;
// 	bottom: 0;
// 	left: 0;
// 	overflow: hidden;
// 	position: fixed;
// 	right: 0;
// 	top: 0;
//   }
  
  video,
  source {
	bottom: 0;
	left: 0;
	min-height: 100%;
	min-width: 100%;
	position: absolute;
  }
  
  .loaded .ytplayer-container {
	display: block;
  }
  
  .loaded .placeholder-image {
	opacity: 0;
  }
  
  .ytplayer-container {
	bottom: 0;
	height: 100%;
	left: 0;
	min-width: 100%;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;
  }
  
  .placeholder-image {
	height: 100%;
	left: 0;
	min-height: 100%;
	min-width: 100%;
	position: fixed;
	top: 0;
	z-index: 1;
  }
  
  .ytplayer-shield {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2;
  }
  
  .ytplayer-player {
	position: absolute;
	max-width: unset;
	transform: translateY(-50%);
	top: 50% !important;
  }