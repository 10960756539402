// .services {
// 	padding: $gap 0;

// 	.columns {
// 		padding: $gap / 2 0;

// 		.column {
// 			padding: 0 $gap / 2;
// 			ul {
// 				li {
// 				}
// 			}
// 		}

// 		&:nth-of-type(odd) {
// 			.column:first-of-type {
// 				order: 2;
// 			}

// 			.column:last-of-type {
// 				order: 1;
// 			}
// 		}
// 	}
// }
.performance {
    .intro {
        margin-top: $gap / 2;
    }
}
.posts-services{
	padding: $gap 0;
	article.post-service {
		position: relative;

		figure {
			background: $primary;
			img {
				display: block;
			}
		}

		a {
			display: block;
		}

		.service-headshot {
			display: block;
			// filter: grayscale(90%);
			opacity: 0.75;
			transition: all 0.2s ease;
		}

		.service-header {
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-radius: 2px;
			position: absolute;
			bottom: $gap / 2;
			left: $gap / 2;
			color: #fff;
			text-align: center;
			width: calc(100% - #{$gap});
			height: calc(100% - #{$gap});
			box-sizing: border-box;
			padding: $gap / 4;
			transition: all 0.5s ease;
            opacity: 1;
            background-color: rgba($primary, 0.5);
			

			.service-title {
				text-transform: uppercase;
				font-family: $family-sans-serif-alt-light;
				letter-spacing: 0.05em;
			}
		}

		&:hover {
			.service-headshot {
				display: block;
			}

			.service-header {
                background-color: rgba($primary, 0.75);
				box-shadow: 2px 2px 8px rgba($black, 0.1);
				opacity: 1;
			}
		}
	}
}