.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 720px;
  margin: 0 auto;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 720px;
  margin: 0 auto;
}

.entry-content img,
.entry-content iframe,
.entry-content object,
.entry-content embed {
  max-width: 720px;
  margin: 0 auto;
}

// @include media-breakpoint-up('lg') {
// 	.video-container { padding-bottom: 44.5%; }
// 	.post-blog .video-container { padding-bottom: 56.25%; }
// }

// @include media-breakpoint-up('xl') {
// 	.video-container { padding-bottom: 37.15%; }
// 	.post-blog .video-container { padding-bottom: 56.25%; }
// }
