.experience360{
	.ff-stream,
	#ff-stream-1 {
		padding: $gap 0;
		background-color: transparent;

		.ff-header {
			display: none;
		}

		.ff-loadmore-wrapper {
			.ff-btn {
				background-color: $link;
				color: $link-invert;
				border-radius: 2px;
				border: 2px solid $link;

				&:hover {
					background-color: $link-invert;
					color: $link;
				}
			}
		}
	}
}
