.hero.page {
	margin-top: 50px;
	background-color: $brand-heading;
	@media (min-width: $desktop) {
		margin-top: 90px;
    }
    
	.hero-body {
		padding: $gap 0 $gap * .55 0 !important;
		height: 20rem;
        
		.container {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
            height: 100%;
            h1 {
                order: 2;
            }
            &.with-icon {
                .header-icon {
                    order: 1;
                    max-width: 40px;
                    @media (min-width: $tablet) {
                        max-width: 70px;
                        position: absolute;
                        left: 0;
                        // bottom: 0px;
                        
                    }
                }
                h1 {
                    @media (min-width: $tablet) {
                        padding-left: 60px;
                    }
                }
            }
		}
	}
}
.history .hero.page {
	.hero-body {
		height: 8rem;
	}
}
.post-type-archive-success_stories .hero.page,
.development .hero.page {
    background-color: $brand-uplift;
    
	.hero-body {
		height: 8rem;
	}
}
.experience360 .hero.page {
    background-color: $brand-celebrate;
    
	.hero-body {
		height: 8rem;
	}
}
.benefits .hero.page {
    background-color: $brand-belong;

	.hero-body {
		height: 8rem;
	}
}
.careers .hero.page {
    background-color: $brand-refine;
    
	.hero-body {
		height: 11rem;
	}
}

.post-type-archive-properties {
	.hero {
		.hero-body {
			padding: 0 !important;
			height: 25rem;

			.map-container {
				display: block;
				height: 100%;

				h1 {
					position: absolute;
					left: -9999px;
				}
			}
		}
	}
}

.home .hero.home {
	background-image: url('/wp-content/uploads/2019/03/page-header-home.jpg');
	background-size: cover;
	background-position: center center;
}
.post-type-archive-360people .hero.page {
	// background-image: url('/wp-content/uploads/2019/03/page-header-people.jpg');
	background-size: cover;
	background-position: center top;
}
.history .hero.page {
	// background-image: url('/wp-content/uploads/2019/03/page-header-history.jpg');
	background-size: cover;
	background-position: center top;
}
.performance .hero.page {
	// background-image: url('/wp-content/uploads/2019/03/page-header-performance.jpg');
	background-size: cover;
	background-position: center center;
}
.personality .hero.page {
	// background-image: url('/wp-content/uploads/2019/03/page-header-personality.jpg');
	background-size: cover;
	background-position: center top;
}
.culture .hero.page {
	// background-image: url('/wp-content/uploads/2019/03/page-header-culture.jpg');
	background-size: cover;
	background-position: center top;
}
