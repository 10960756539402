.personality {
    .intro {
        margin-top: $gap *.75;
        h6 {
            margin-bottom: 10px;
        }
    }
    .missions {
        padding: $gap 0 0 0;
        .columns {
            align-items: stretch;
            .column {
                padding: $gap / 2;
                background-color: $brand-heading;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                h6 {
                    margin-bottom: $gap / 5;
                    opacity: 0.75;
                }

                h3 {
                    margin-bottom: $gap / 2;
                }

                p {}

                @media(max-width: $desktop){
                    &:last-of-type {
                        
                        &.image {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .values {
        padding: 0;
        .columns {
            align-items: stretch;
            .column {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                padding: $gap;
                &.content {
                    padding: $gap;
                    p {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}