///////////////////////////////////////////////
//////    GRAVITY FORMS RESET STYLES     //////
///////////////////////////////////////////////

// If you use these styles, you must go to the Gravity Forms settings in the WordPress back end and set Output CSS to No.

////----- Core Containers
.gform_heading,
.gform_body,
.gform_footer {
	clear: both;
	padding-bottom: 20px;
}

////----- Fields Container
ul.gform_fields {
	list-style: none !important;
	// margin: 0 -15px;
	margin: 0;
	padding: 0;
}

////----- Field Containers and their Contents
li.gfield {
	clear: both;
	overflow: auto;
	padding: 0 15px 20px 0;
}
.ginput_container {
	span {
		margin-bottom: 10px;
	}
	input,
	select,
	textarea {
		background-color: $grey-lighter;
		border: none;
		font-size: 1em;
		padding: 10px;
		&.large {
			width: 100%;
		}
		&.medium {
			width: 66%;
		}
		&.small {
			width: 33%;
		}
	}

	input, 
	textarea {
		margin: 2px;
	}
}
label.gfield_label {
	display: inline-block;
	padding-bottom: 0px;
	font-family: $family-sans-serif-alt-light;
	letter-spacing: 0.09375em;
	text-transform: uppercase;

	.left_label & {
		float: left;
		width: 25%;
	}
	.right_label & {
		float: right;
		width: 25%;
	}
}

.gfield_required {
	padding-left: 5px;
}

////----- Checkboxes and Radios
ul.gfield_checkbox,
ul.gfield_radio {
	list-style: none;
	padding: 0;
	input {
		margin-right: 10px;
		position: relative;
		top: -1px;
	}
}
ul.gfield_radio {
	input {
		margin-left: 2px;
	}
}

////----- Section Break
.gsection {
	h2 {
		border-bottom: 3px solid black;
		padding-bottom: 15px;
	}
}

////----- Page Break
.gf_progressbar_wrapper {
	padding-bottom: 15px;
}
.gf_progressbar {
	background: gray;
}
.gf_progressbar_percentage {
	background: red;
	padding: 5px 10px;
	text-align: right;
}

////----- Time Field
.gfield_time_hour, .gfield_time_minute, .gfield_time_ampm {
	display: inline;
	display: inline-block;
}
.gfield_time_hour, .gfield_time_minute {
	label {
		display: block;
	}
}
.gfield_time_ampm {
	vertical-align: top;
}

////----- Validation and Errors
.validation_error {
	font-size: 1em;
	// font-weight: bold;
	padding-bottom: 5px;
	color: $brand-pink;
}

li.gfield {
	&.gfield_error {
		background: rgba($brand-pink, 0.0625);
		margin-bottom: 15px;
		padding-top: 15px;
		label.gfield_label {
			font-weight: bold;
			color: $brand-pink;
		}
	}
	&.gfield_contains_required {

	}
	.validation_message {
		font-weight: bold;
		margin-top: 5px;
		font-size: 12px;
		color: $brand-pink;
	}
}

.gform_validation_container {
	display: none;
}

////----- CSS Ready Classes
.ginput_full {
	display: block;
	input, select, textarea {
		margin-bottom: 5px;
		width: 100%;
	}
	label {
		display: block;
	}
}
.ginput_left {
	float: left;
	padding-right: 10px;
	width: 50%;
	input, select, textarea {
		margin-bottom: 5px;
		width: 100%;
	}
	label {
		display: block;
	}
}
.ginput_right {
	float: right;
	width: 50%;
	input, select, textarea {
		margin-bottom: 5px;
		width: 100%;
	}
	label {
		display: block;
	}
}
.ginput_right {
	float: right;
	width: 50%;
}

//----- Layout Columns
li.gf_left_half {
	clear: none;
	float: left;
	padding-right: 10px;
	padding-bottom: 10px;
	width: 50%;
}
li.gf_right_half {
	clear: none;
	float: right;
	padding-bottom: 10px;
	width: 50%;
}
li.gf_left_third {
	clear: none;
	float: left;
	padding-right: 10px;
	padding-bottom: 10px;
	width: 33%;
}
li.gf_middle_third {
	clear: none;
	float: left;
	padding-right: 10px;
	padding-bottom: 10px;
	width: 34%;
}
li.gf_right_third {
	clear: none;
	float: right;
	padding-bottom: 10px;
	width: 33%;
}
li.gf_first_quarter {
	clear: none;
	float: left;
	padding-right: 10px;
	padding-bottom: 10px;
	width: 25%;
}
li.gf_second_quarter {
	clear: none;
	float: left;
	padding-right: 10px;
	padding-bottom: 10px;
	width: 25%;
}
li.gf_third_quarter {
	clear: none;
	float: left;
	padding-bottom: 10px;
	width: 25%;
}
li.gf_fourth_quarter {
	clear: none;
	float: right;
	padding-bottom: 10px;
	width: 25%;
}

@media screen and (max-width:480px){
	li {
		&.gf_left_half,
		&.gf_right_half,
		&.gf_left_third,
		&.gf_middle_third,
		&.gf_right_third,
		&.gf_first_quarter,
		&.gf_second_quarter,
		&.gf_third_quarter,
		&.gf_fourth_quarter {
			width: 100%;
		}
	}
}

//----- List Columns
.gf_list_2col {
	ul.gfield_checkbox,
	ul.gfield_radio {
		li {
			float: left;
			padding-bottom: 10px;
			width: 50%;
		}
	}
}
.gf_list_3col {
	ul.gfield_checkbox,
	ul.gfield_radio {
		li {
			float: left;
			padding-bottom: 10px;
			width: 33%;
		}
	}
}
.gf_list_4col {
	ul.gfield_checkbox,
	ul.gfield_radio {
		li {
			float: left;
			padding-bottom: 10px;
			width: 25%;
		}
	}
}
.gf_list_5col {
	ul.gfield_checkbox,
	ul.gfield_radio {
		li {
			float: left;
			padding-bottom: 10px;
			width: 20%;
		}
	}
}
.gf_list_inline {
	ul.gfield_checkbox,
	ul.gfield_radio {
		li {
			display: inline-block;
			padding-right: 10px;
			padding-bottom: 10px;
		}
	}
}

@media screen and (max-width:480px){
	.gf_list_2col,
	.gf_list_3col,
	.gf_list_4col,
	.gf_list_5col {
		ul.gfield_checkbox,
		ul.gfield_radio {
			li {
				width: 100%;
			}
		}
	}
}

.gform_button {
	background: $brand-blue;
	// border: none;
	color: #fff;
	padding-left: $gap / 2;
	padding-right: $gap / 2;
	&:hover {
		background: $brand-blue-invert;
	}
}