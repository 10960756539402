.timeline {
  padding: $gap 0;
  .timeline-top {
	@media (min-width: $tablet) {
		height: 300px !important;
		max-height: 300px !important;
	}
	@media (min-width: $desktop) {
		height: 400px !important;
		max-height: 400px !important;
	}
	@media (min-width: $fullhd) {
		height: 500px !important;
		max-height: 500px !important;
    }
    .timeline-item {
      font-size: 1.5em;
      margin-bottom: $gap;
      img {
          width: 100%;
      }
      @media (min-width: $tablet) {
        // max-height: 500px;
      }
      &.d-none {
        display: none;
      }
    }
  }
  .timeline-bottom {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    border-top: 3px solid $brand-blue;
	padding-top: 10px;
	height: 230px;
    .timeline-selector {
      display: flex;
      flex-direction: column;
      max-width: 288px;
      margin-right: $gap;
      position: relative;
      min-width: 288px;
      padding-top: $gap / 2;
      cursor: pointer;

      &:before {
        content: '';
        background-color: #fff;
        border: 3px solid $brand-blue;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: -20px;
        left: $gap / 3;
      }

      &.is-active {
        &:before {
          content: '';
          background-color: $brand-blue;
        }
      }
    }
  }
  .pagination {
    max-width: 124px;
    margin: 0 auto;
    a {
      // display: inline-block;
      // margin: $gap / 5;
      font-size: 1.5em;
      background-color: $brand-heading;
      color: #fff;
      border-radius: 50%;
      transition: all 0.3s ease;

      &:hover {
        background-color: $brand-blue;
      }
    }
  }
}
.cta-banner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  padding: $gap 0;
  min-height: 480px;
  p {
    font-size: 1.5em;
    max-width: 1170px;
    margin: 0 auto;
  }
  .button {
    max-width: 288px;
    display: inline-block !important;
    margin: ($gap / 1.5) auto 0;
  }
}
