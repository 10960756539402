main {
	padding-left: $gap / 2;
	padding-right: $gap / 2;
}

.example-row {
	.demo {
		padding: $gap null;
		margin: null null $gap / 2 null;
		background-color: $grey-lighter;
		box-sizing: border-box;

		p {
			text-transform: uppercase;
			text-align: center;
			font-size: 12px;
			letter-spacing: 2.5px;
			color: rgba($black, 0.35);
		}
	}
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.posts {
	padding: $gap 0;
}

.intro {
	padding: $gap / 2  0 $gap / 4 0;
	margin: 0 auto;
	max-width: 960px;
	text-align: center;
	p {
		font-size: 1.5rem;
		margin-bottom: 0;
	}
}
.hero {
	.container {
		padding-right: $gap / 2;
		padding-left: $gap / 2;
	}
}

.flexible-content {
	padding: $gap 0;
	max-width: 1260px;
	margin: 0 auto;
}