.logged-in {
	.navbar.is-fixed-top {
		top: 32px;
	}
}

.navbar {
	padding: 0 $gap / 2;

	.navbar-item {
		padding-top: 5px;
		padding-bottom: 5px;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 0.09375rem;

		&.has-dropdown {
			padding: 0;
		}

		&:hover {
			color: $primary;
			background: transparent;
		}

		&.is-active {
			color: $primary;
			background: transparent;
		}

		&.nav-button,
		&.nav-button:hover {
			background: $secondary !important;
			color: #fff;
		}

		img {
			transition: all 0.2s ease;
			max-height: 2.75rem;

			@media (min-width: $desktop) {
				max-height: 5rem;
			}
		}
		
		.navbar-link:not(.is-arrowless)::after {
			border-width: 2px;
			// border-color: #fff;
			margin-top: -0.375em;
			right: 1.125em;
		}
		.navbar-link.nav-button:not(.is-arrowless)::after {
			border-color: #fff;
		}
	}

	@media screen and (min-width: $desktop) {
		.navbar-dropdown {
			background-color: white;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border: none;
			box-shadow: 0 8px 8px rgba($black, 0.1);
			display: none;
			font-size: 0.875rem;
			right: 0;
			min-width: 288px;
			max-width: 330px;
			left: -92px;
			position: absolute;
			top: 100%;
			z-index: 20;

			a.navbar-item:hover {
				background-color: transparent;
				color: $primary;
			}
		}
	}
}
.hero.page {
	.sub-menu {
		min-height: 32px;
		background-color: $brand-blue;
		text-align: center;
		text-transform: uppercase;
		z-index: 1;
		.navbar {
			justify-content: center;
			min-height: 2.5rem;
			.navbar-item {
				padding-left: $gap / 2;
				padding-right: $gap / 2;
				font-size: .875em;
				letter-spacing: 1.5px;
				font-family: $family-sans-serif-alt-light;
				&:not(.button) {
					color: #fff;
				}
				&.button {
					margin-top: 4px;
					// border: 1px solid #fff;
				}
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
}