.wp-editor {
	ul {
		list-style: disc !important;
		margin-left: 10px;

		li {
			margin-left: 20px;
		}
	}
}
