.posts-properties {
	article.post-property {
		position: relative;

		figure {
			background: $primary;
		}

		a {
			display: block;
		}

		.property-headshot {
			display: block;
			// filter: grayscale(90%);
			// opacity: 0.5;
			transition: all 0.2s ease;
		}

		.property-header {
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-radius: 2px;
			position: absolute;
			bottom: $gap / 2;
			left: $gap / 2;
			color: #fff;
			text-align: center;
			width: calc(100% - #{$gap});
			height: calc(100% - #{$gap});
			box-sizing: border-box;
			padding: $gap / 4;
			transition: all 0.5s ease;
			opacity: 0;

			.property-title {
				text-transform: uppercase;
				font-family: $family-sans-serif-alt-light;
				letter-spacing: 0.05em;
			}

			.property-city {
				font-size: 1.125rem;
				font-family: $family-sans-serif-alt-light;
				font-weight: 400;
			}

		}

		&:hover {

			.property-headshot {
				display: block;
				filter: grayscale(70%);
			}

			.property-header {
				background-color: rgba($primary, 0.75);
				box-shadow: 2px 2px 8px rgba($black, 0.1);
				opacity: 1;
			}
		}
	}
}