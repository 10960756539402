.breadcrumbs {
	background-color: #f8f9fa;

	ul {
		list-style: none;
		margin: 0;

		li {
			margin: 0.1875rem 0.5rem 0.1875rem 0;
			display: inline-block;
			font-size: 0.875rem;
			line-height: 1.5rem;
			padding: 0;

			&::before {
				content: none;
				margin: 0;
			}

			&.bread-current {
				// font-size: 0.6875rem;
				line-height: 1.5rem;
			}

			a {
				transition: all 0.2s ease;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
