.popover {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
    background-color: rgba(255,255,255,0.5);
	padding: $gap / 2;
	display: none;
	@media (min-width: tablet) {
		padding: $gap;
	}
	&.is-active {
		display: block;
	}
	.popover-close {
		position: absolute;
		right: $gap / 4;
		top: $gap / 4;
		font-size: 1.5em;
		border-radius: 50%;
		background: $brand-heading;
		color: #fff;
		width: 50px;
		height: 50px;
		text-align: center;
        line-height: 50px;
        z-index: 5;
	}
	.popover-guts {
		height: calc(100vh - 90px);
		position: absolute;
		top: 70px;
		left: 50%;
		transform: translateX(-50%);
		background: #fff;
		padding: $gap / 2;
		width: 100%;
		overflow-y: scroll;
		box-shadow: 0px 0px 32px rgba($black,.10);
		border-radius: 2px;
		@media (min-width: $tablet) {
			top: 90px;
			height: calc(100vh - 110px);
			padding: $gap / 2;
			width: calc(100% - 64px);
		}
		@media (min-width: $desktop) {
			top: 140px;
			height: calc(100vh - 180px);
			width: calc(100% - 128px);
        }
        &.services-guts {
            @media (min-width: $tablet) {
                height: auto;
            }
            @media (min-width: $desktop) {
                top: 50%;
                transform: translate(-50%, -50%);
                height: auto;
            }
        }
	}
}