.off-canvas {
	position: fixed;
	top: 50px;
	right: -288px;
	height: calc(100vh - 50px);
    overflow-x: hidden;
    overflow-y: scroll;
	background: #fff;
	width: 288px;
	transition: all 0.3s ease-in;
	z-index: 10;

	&.is-active {
		transform: translateX(-288px);
		box-shadow: -10px 0 16px rgba($black, 0.1);
		
	}

	.sliding-drawer-close {
		padding: 6px;
		margin: 0;
		border: 1px solid #fff;
		border-radius: 50%;
		width: 32px;
		height: 32px;
		z-index: 3;
		position: absolute;
		left: 10px;
		top: 10px;
		color: white;
		font-size: 18px;
		line-height: 1;
		text-align: center;
		box-shadow: 1px 1px 4px rgba($black, 0.1);

		svg path {
			box-shadow: 1px 1px 4px rgba($black, 0.1);
		}
    }
	&.sliding-drawer-contact {
		padding-top: $gap / 2;
		.sliding-drawer-close {
			border: 1px solid $brand-heading;
			color: $brand-heading;
		}
		h5 {
			font-family: $family-sans-serif-alt-light;
			margin-bottom: 5px;
			letter-spacing: 0.05em;
			margin-top: $gap / 2;
		}
		h3 {
			margin-top: $gap / 2;
		}
	}
	.featured-image {
		position: relative;

		.person-candid {
			position: relative;
			top: 0;
			left: 0;
			z-index: 1;
		}

		.person-header {
			z-index: 2;
			position: absolute;
			bottom: $gap / 2;
			left: $gap / 2;
			text-shadow: 1px 1px 4px rgba($black, 0.1);

			.person-name {
				display: block;
			}

			.person-job-title {
				text-transform: uppercase;
			}

			.person-since {
				text-transform: uppercase;
			}
        }
        &.no-image {
            .person-candid {
                display: none;
            }
            
            .person-header {
                z-index: 2;
                position: relative;
                text-shadow: none;
                bottom: 0;
                top: 32px;
    
                .person-name {
                    display: block;
                }
    
                .person-job-title {
                    text-transform: uppercase;
                }
    
                .person-since {
                    text-transform: uppercase;
                }
            }
        }
	}

	.content,
	.content-contact {
		padding: $gap / 2;
		margin: 0;
	}
}

@media (min-width: $tablet) {
	.off-canvas {
		right: -576px;
		width: 576px;

		&.is-active {
			transform: translateX(-576px);
		}
	}
}

@media (min-width: $desktop) {
	.off-canvas {
		top: 90px;
		height: calc(100vh - 90px);
		right: -760px;
		width: 760px;

		&.is-active {
			transform: translateX(-760px);
		}
	}
}


.post-type-archive-success_stories {
    .sliding-drawer-close {
        border-color: $black;
        color: $black;
        svg path {
            
            fill: $black;
        }
    }
}