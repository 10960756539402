.property {
	.logo {
		max-height: 90px;
		width: auto;
		display: block;
		margin-bottom: $gap / 2;
	}
	.property-title {

	}
	.meta {
		color: $brand-blue;
		text-transform: uppercase;	
	}
	.management {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		margin-bottom: $gap / 2;
		.manager-headshot {
			max-width: 80px;
			height: 80px;
			display: block;
			margin-right: $gap / 4;
			border-radius: 50%;
		}
		p {
			margin: 0;
			font-family: $family-sans-serif;
			span {
				text-transform: uppercase;
				letter-spacing: 0.05em;
				font-size: 80%;
			}
		}
	}
	.content {
		margin: 0;
		padding: 0;
		.awards {
			margin: ($gap / 2) 0;
			.award {
				padding: 0 0 ($gap / 2) 0;
				margin: 0 0 ($gap / 4) 0;
				border-bottom: 1px dashed $grey-lighter;
				p {
					margin-bottom: 0;
				}
			}
        }
        .tripadvisor-widget {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    margin: 0;
                    padding: 0;
                }
            }
            a {
                .review-badge {
                    display: block;
                    width: 25%;
                    max-width: 72px;
                    float: left;
                    margin: 0 10px 15px 0;
                    @media (max-width: $tablet) {
                        float: none;
                        max-width: 48px;
                    }
                }
                h4 {
                    margin-bottom: 0;
                }
                .review-count {
                    p {
                        color: #00a680;
                        margin-bottom: 0;
                        span {
                            color: $grey;
                            font-size: 13px;
                            font-weight: bold;
                            @media (max-width: $tablet) {
                                display: block;
                            }
                        }
                    }
                    i, svg {
                        color: #00a680;
                        fill: #00a680;
                    }
                }
                
            }
        }
	}
}