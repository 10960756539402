footer.site-footer {
	padding-bottom: $gap / 2;
	.site-footer-top {
		.navbar-center {
			display: flex;
			justify-content: center;
			a {
				color: white;
				text-transform: uppercase;
				font-size: 14px;
				letter-spacing: 1.5px;
				&:hover {
					background-color: transparent;
					opacity: 0.8;
				}
			}
		}
	}
	.footer-copyright {
		padding-top: 20px;
		border-top: 1px solid $grey-darker;
		color: $brand-text;
		p {
			font-size: $size-7;
			margin: 0;
			a {
				text-decoration: underline;
				&:hover {
					color: $brand-pink;
				}
			}
		}
	}
}