.posts-success {
	article.post-success {
		position: relative;
		figure {
			background: $primary;
		}
		a {
			display: block;
		}
		.success-headshot {
			display: block;
			filter: grayscale(25%);
			opacity: 0.65;
			transition: all 0.2s ease;
		}
		.success-header {
			border-radius: 2px;
			position: absolute;
			bottom: $gap / 2;
			left: $gap / 2;
			color: #fff;
			text-align: center;
			width: calc(100% - #{$gap});
			box-sizing: border-box;
			padding: $gap / 4;
			transition: all 0.3s ease;
			h3 {
				font-family: $family-sans-serif-alt-light;
				font-weight: 300;
			}
			p {
				text-transform: uppercase;
				letter-spacing: 0.05em;
				font-family: $family-sans-serif-alt-light;
				margin: 0;
				font-size: 0.925em;
			}
		}
		&:hover {
			.success-headshot {
				filter: grayscale(0%);
				opacity: 1;
				box-shadow: 0px 0px 16px rgba($black, 0.125);
			}
			.success-header {
				background-color: #fff;
				color: $brand-text;
				h3 {
					color: $primary;
					
				}
				p {
					color: $primary;
					text-transform: uppercase;
					letter-spacing: 0.05em;
				}
			}
		}
	}
}