body {
	color: $brand-text;
	font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	line-height: 1;
	font-family: $family-sans-serif-alt;
	font-weight: 700;
}

:not(.has-text-white) { 
	& > h1,
	& > h2,
	& > h3,
	& > h4,
	& > h5,
	& > h6,
	& > .h1,
	& > .h2,
	& > .h3,
	& > .h4,
	& > .h5,
	& > .h6 {
		color: $brand-heading;
	}
}	
h1 {
	font-size: 3rem;
	letter-spacing: -0.02em;
}

h2 {
	font-size: 2.75rem;
	letter-spacing: -0.02em;
}

h3 {
	font-size: 2.1875rem;
	letter-spacing: -0.02em;
	margin-bottom: 0.533333333rem;
}

h4 {
	font-size: 1.5rem;
}

h5 {
	font-size: 1rem;
}

h6 {
	font-size: .875rem;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 0.09375rem;
	font-family: $family-sans-serif-alt-light;
}
.h1 {
	font-size: 3rem;
	letter-spacing: -0.02em;
}

.h2 {
	font-size: 2.75rem;
	letter-spacing: -0.02em;
}

.h3 {
	font-size: 2.1875rem;
	letter-spacing: -0.02em;
	margin-bottom: 0.533333333rem;
}

.h4 {
	font-size: 1.5rem;
}

.h5 {
	font-size: 1rem;
}

.h6 {
	font-size: .875rem;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 0.09375rem;
	font-family: $family-sans-serif-alt-light;
}
.flexible-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        font-family: $family-sans-serif-alt-light;
        strong, b {
            font-family: $family-sans-serif-alt;
        }
    }
    ul {
        list-style: disc outside;
        margin-left: $gap / 2;
    } 
}
p {
	font-size: 1rem;
	line-height: 1.5;
	margin-bottom: 1rem;
}

ul:not(.gform_fields) {
	li {
		// padding-left: $gap / 4;
		// font-size: 0.875rem;
		line-height: 2;
		&:before {
			// margin-right: $gap / 4;
			// content: "•";
			// color:  $primary;
		}
	}
}

@media (min-width: $desktop) {
	h1,
	.h1 {
		font-size: 3.75rem;
	}

	h2,
	.h2 {
		font-size: 3.25rem;
	}

	h3,
	.h3 {
		font-size: 2.75rem;
	}

	h4,
	.h4 {
		font-size: 2rem;
	}

	h5,
	.h5 {
		font-size: 1.125rem;
	}

	h6,
	.h6 {
		font-size: 1rem;
	}

	p {
		font-size: 1.125rem;
		margin-bottom: 1.25rem;
	}
	
	ul:not(.gform_fields) {
		li {
			padding-left: $gap / 3.5;
			font-size: 1rem;
			&:before {
				margin-right: $gap / 3.5;
			}
		}
	}
}