.home{
	.home-intro {
		padding: $gap 0;
		// max-width: 1230px;
		p {
			font-size: 1.25em;
			z-index: 2;
			position: relative;
			color: $brand-heading;
			padding-right: 60px;

			&:after {
				width: 160px;
				height: 160px;
				background-color: $brand-blue;
				z-index: -100;
				display: block;
				position: absolute;
				top: 0; 
				right: -50px;
				content: '';
				border-radius: 50%;
			}
		}
		@media(min-width: $desktop) {
			p {
				font-size: 1.5em;
				padding-right: 125px;
				padding-left: $gap;
				&:after {
					width: 250px;
					height: 250px;
					top: -20px; 
					right: -45px;
				}
			}
		}
	}

	.home-cards {
		padding: 0 0 $gap 0;
		.home-card {
			a {
				display: block;
				position: relative;
				.home-card-info {
					position: absolute;
					bottom: $gap / 2;
					left: $gap / 2;
					text-shadow: 2px 2px 4px rgba($black, 0.25);
					overflow: hidden;
					span.more {
						text-transform: uppercase;
						letter-spacing: 1.5px;
						transform: translateY(80px);
						transition: all 0.3s ease;
						display: block;
					}
				}
				&:hover {
					.home-card-info {
						span.more {
							transform: translateY(0);
						}
					}
				}
			}
		}
	}
}
