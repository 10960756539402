.person {
	width: 100%;
	max-width: 1024px;
	margin-left: auto;
	margin-right: auto;
	.featured-image {
		position: relative;

		.person-candid {
			position: relative;
			top: 0;
			left: 0;
			z-index: 1;
		}

		.person-header {
			z-index: 2;
			position: absolute;
			bottom: $gap / 2;
			left: $gap / 2;
			text-shadow: 1px 1px 4px rgba($black, 0.1);

			.person-name {
				display: block;
			}

			.person-job-title {
				text-transform: uppercase;
				margin-bottom: 2px;
			}

			.person-since {
				text-transform: uppercase;
			}
		}
	}

	.content {
		padding: $gap / 2;
		margin: 0;
	}
}