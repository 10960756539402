progress {
	/* Positioning */
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999999;

	/* Dimensions */
	width: 100%;
	height: 5px;

	/* Reset the appearance */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	/* Get rid of the default border in Firefox/Opera. */
	border: none;

	/* Progress bar container for Firefox/IE10+ */
	background-color: #fff;

	/* Progress bar value for IE10+ */
	color: $brand-blue;
}

progress::-webkit-progress-bar {
	background-color: #fff;
}

progress::-webkit-progress-value {
	background-color: $brand-blue;
}

progress::-moz-progress-bar {
	background-color: $brand-blue;
}

.logged-in {
	progress {
		top: 32px;
	}
}

.site-header {
	background: gray;

	.top-bar {
		display: block;
	}
}
